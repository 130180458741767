import { Controller } from "stimulus";

import ajaxCallbacks from "lib/ajax_events";
import cssClasses from "lib/css_classes";

class ContactSyncBaseController extends Controller {
  public readonly requesterTarget!: Element;
}

// Indicate the status of the contact sync functionality to the user during the
// remote sync request.
export default class extends (Controller as typeof ContactSyncBaseController) {
  public static targets = ["requester"];

  public initialize() {
    this.setAjaxEventListeners();
  }

  private setAjaxEventListeners() {
    this.requesterTarget.addEventListener(ajaxCallbacks.send, () => {
      this.requesterTarget.classList.add(
        ...cssClasses.disabled,
        ...cssClasses.loading
      );
    });

    this.requesterTarget.addEventListener(ajaxCallbacks.success, () => {
      this.requesterTarget.classList.remove(...cssClasses.loading);
      this.requesterTarget.innerHTML = this.successMessage;
    });

    this.requesterTarget.addEventListener(ajaxCallbacks.error, () => {
      this.requesterTarget.classList.remove(...cssClasses.loading);
      this.requesterTarget.innerHTML = this.errorMessage;
    });
  }

  private get successMessage(): string {
    return "Contact Sync Successful";
  }

  private get errorMessage(): string {
    return "Contact Sync Error";
  }
}
