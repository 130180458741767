import { Controller } from "stimulus";

class AlternateFormatBaseController extends Controller {
  public readonly element!: HTMLButtonElement;
}

// Modify the action of a form to go to an alternate format
export default class extends (Controller as typeof AlternateFormatBaseController) {
  public static targets = [];

  public submit(event: Event): void {
    event.preventDefault();

    const formElement = this.element.form;

    if (formElement === null) {
      console.error("Unable to locate form for Stimulus element");
      console.error(this.element);

      return;
    }

    const currentAction = formElement.action;
    const newAction = this.newActionForUrl(currentAction, this.format);

    formElement.action = newAction;
    formElement.submit();
    formElement.action = currentAction;
  }

  private newActionForUrl(url: string, action: string): string {
    const urlComponents = url.split("/");
    const lastUrlComponent = urlComponents.pop();

    if (lastUrlComponent === undefined) {
      console.error("Unable to process URL to assign new action");
      console.error(url);

      return "";
    }

    const newUrlComponent = lastUrlComponent.split(".")[0];
    urlComponents.push(newUrlComponent);

    return `${urlComponents.join("/")}.${action}`;
  }

  private get format(): string {
    const defaultValue = "csv";

    return this.data.get("format") || defaultValue;
  }
}
