import { Controller } from "stimulus";

import moment from "moment";

class TimeOffHourPopulationBaseController extends Controller {
  public readonly startTimeTarget!: HTMLInputElement;
  public readonly endTimeTarget!: HTMLInputElement;
  public readonly hoursTarget!: HTMLInputElement;
}

// Automatically update the "hours" field of a time off adjustment group as the start and end times change.
export default class extends (Controller as typeof TimeOffHourPopulationBaseController) {
  public static targets = ["startTime", "endTime", "hours"];

  public updateHours(): void {
    const hours = moment
      .duration(
        moment(this.endTimeTarget.value, this.timeFormat).diff(
          moment(this.startTimeTarget.value, this.timeFormat)
        )
      )
      .asHours();

    this.hoursTarget.value = hours.toString();
  }

  public initialize(): void {
    this.updateHours();
  }

  private get timeFormat(): string {
    return "h:m a";
  }
}
