import { Controller } from "stimulus";

// Ensure that a phone number input adheres to a specific format:
// XXX-XXX-XXXX
export default class extends Controller {
  public static targets = [];

  public handleInput(event: Event): void {
    const phoneNumberInputElement = event.target as HTMLInputElement;
    let phoneNumberInput = phoneNumberInputElement.value;

    // Strip out non-digit characters.
    phoneNumberInput = phoneNumberInput.replace(/\D/g, "");

    // Ensure we're working with a max of ten characters.
    phoneNumberInput = phoneNumberInput.substring(0, 10);

    // Format the phone number to have dashes between the segments.
    phoneNumberInput = this.formatAsPhone(phoneNumberInput);

    // Set the input value to the transformed value.
    phoneNumberInputElement.value = phoneNumberInput;
  }

  private formatAsPhone(phoneNumber: string): string {
    const firstThree = phoneNumber.substring(0, 3);
    const secondThree = phoneNumber.substring(3, 6);
    const lastFour = phoneNumber.substring(6, 10);

    switch (phoneNumber.length) {
      case 4:
      case 5:
      case 6:
        return `${firstThree}-${secondThree}`;
      case 7:
      case 8:
      case 9:
      case 10:
        return `${firstThree}-${secondThree}-${lastFour}`;
      default:
        return phoneNumber;
    }
  }
}
