import Rails from "@rails/ujs";
import debounce from "lodash.debounce";
import { Controller } from "stimulus";

import ajaxCallbacks from "lib/ajax_events";

class DebouncedBaseController extends Controller {
  public readonly element!: HTMLFormElement;
}

// This controller allows for various debounced actions to be taken on DOM elements.
export default class extends (Controller as typeof DebouncedBaseController) {
  public static targets = [];

  // Submit the form after some number of inactive milliseconds or some maximum number of
  // milliseconds, whichever comes first.
  //
  // @see https://github.com/lodash/lodash/blob/master/debounce.js for docs on debounce()
  public debouncedSubmit = debounce(this.submitForm, this.debounceMs, {
    maxWait: this.debounceMaxWaitMs,
  });

  // Set up some basic listeners to handle state for the entire controller.
  public initialize() {
    // Whenever the form element submits by some method other than the debouncedSubmit,
    // we need to make sure we don't double submit.
    this.element.form.addEventListener(ajaxCallbacks.send, () => {
      this.debouncedSubmit.cancel();
    });
  }

  // Do not manually call this from an action
  private submitForm() {
    Rails.fire(this.element.form, "submit"); // Submit with Rails for AJAX
  }

  // Set the maximum debounce wait time to either the value specified by the HTML or a default.
  private get debounceMaxWaitMs(): number {
    const defaultValue = 2000;

    if (this.data.has("debounceMaxWaitMs")) {
      return parseInt(
        this.data.get("debounceMaxWaitMs") || `${defaultValue}`,
        10
      );
    }

    return defaultValue;
  }

  // Set the default debounce wait time to either the value specified by the HTML or a default.
  private get debounceMs(): number {
    const defaultValue = 500;

    if (this.data.has("debounceMs")) {
      return parseInt(this.data.get("debounceMs") || `${defaultValue}`, 10);
    }

    return defaultValue;
  }
}
