import { Controller } from "stimulus";

import Selectr from "mobius1-selectr";
import "mobius1-selectr/dist/selectr.min.css";

class SelectBaseController extends Controller {
  public readonly element!: HTMLSelectElement;
}

// Wrapper to implement select box library functionality onto a form select field.
export default class extends (Controller as typeof SelectBaseController) {
  public static targets = [];

  public initialize(): Selectr {
    return new Selectr(this.element, {
      searchable: this.searchable,
    });
  }

  private get searchable(): boolean {
    const defaultValue = true;

    if (this.data.has("searchable")) {
      return this.data.get("searchable") === "true";
    }

    return defaultValue;
  }
}
