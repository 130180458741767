class CSSClasses {
  get loading(): string[] {
    return ["loading", "is-loading"];
  }

  get hidden(): string[] {
    return ["hidden", "is-hidden"];
  }

  get disabled(): string[] {
    return ["disabled", "is-disabled"];
  }

  get active(): string[] {
    return ["active", "is-active"];
  }
}

const cssClasses = new CSSClasses();
export default cssClasses;
