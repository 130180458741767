import { Controller } from "stimulus";

import ajaxCallbacks from "lib/ajax_events";
import cssClasses from "lib/css_classes";

class RemovableBaseController extends Controller {
  public readonly disableableTargets!: Element[];
  public readonly requesterTargets!: Element[];
}

// This controller adds the ability to remove any arbitrary object in the DOM.
//
// @example A notification with a close button:
//   <div data-controller="removable" class="notification">
//     <p>Notification content is here</p>
//     <button data-action="click->removable#remove">X</button>
//   </div>
//
// @example An element on a page that can be deleted from the server with a button through AJAX:
//   <div data-controller="removable">
//     <p>Object name</p>
//     <p>Object created at</p>
//     <a href="/delete_object" data-remote="true" data-target="removable#requester">Remove Me</a>
//   </div>
//
// @example A form with a submit button that should be removed after the form request is complete.
//   <div data-controller="removable">
//     <form data-remote="true" data-target="removable.requester">
//       <input name="object_name" value="Object name">
//       <input name="object_description" value="Object description">
//       <button type="submit" data-target="removable.disableable">Submit Form</button>
//     </form>
//   </div>
export default class extends (Controller as typeof RemovableBaseController) {
  public static targets = ["disableable", "requester"];

  // Remove the element that defined the controller.
  public remove(): void {
    const parentElement = this.element.parentElement;

    this.removeElement();

    if (parentElement) {
      this.dispatchNotificationEvent(parentElement);
    }
  }

  // Define some initial variables that will be used throughout the controller,
  // and add listeners to any elements that might be sending requests to the server.
  public initialize(): void {
    this.requesterTargets.forEach((target) => this.addListeners(target));
  }

  // Disable all disableable targets
  private disableButton(): void {
    this.disableableTargets.forEach((element) => {
      element.setAttribute("disabled", "true");
      element.classList.add(...cssClasses.loading);
    });
  }

  // Enable any previously disabled disableable targets.
  private enableButton(): void {
    this.disableableTargets.forEach((element) => {
      element.removeAttribute("disabled");
      element.classList.remove(...cssClasses.loading);
    });
  }

  // Remove the given element from the DOM
  private removeElement(): void {
    const parentElement = this.element.parentNode;

    if (parentElement !== null) {
      parentElement.removeChild(this.element);
    }
  }

  private dispatchNotificationEvent(element: HTMLElement): void {
    const event = document.createEvent("Event");
    event.initEvent(this.notificationEventName, true, true);

    if (element) {
      element.dispatchEvent(event);
    }
  }

  // Add listeners to some object that sends a request to the server.
  private addListeners(listenable: Element): void {
    listenable.addEventListener(ajaxCallbacks.send, () => this.disableButton());
    listenable.addEventListener(ajaxCallbacks.success, () => this.remove());
    listenable.addEventListener(ajaxCallbacks.complete, () =>
      this.enableButton()
    );
  }

  private get notificationEventName(): string {
    return "elementRemoved";
  }
}
