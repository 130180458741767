import { Controller } from "stimulus";

class NestedFieldsBaseController extends Controller {
  public readonly linksTarget!: Element;
  public readonly templateTarget!: Element;
}

// Allow for dynamic additions of forms onto a page.
export default class extends (Controller as typeof NestedFieldsBaseController) {
  public static targets = ["links", "template"];

  public addAssociation(event: Event): void {
    const content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime().toString()
    );

    this.linksTarget.insertAdjacentHTML("beforebegin", content);

    this.dispatchEvent();
  }

  private dispatchEvent(): void {
    const event = new CustomEvent("fieldAdded");
    this.element.dispatchEvent(event);
  }
}
