import { Controller } from "stimulus";

import ajaxCallbacks from "lib/ajax_events";
import cssClasses from "lib/css_classes";

class IndexBaseController extends Controller {
  public readonly contentTargets!: Element[];
  public readonly loadingRequesterTargets!: Element[];
  public readonly loadingSpinnerTargets!: Element[];
  public readonly placeholderTargets!: Element[];
}

// Show list data, placeholders for that data when empty, and spinners when that data is loading.
export default class extends (Controller as typeof IndexBaseController) {
  public static targets = [
    "content",
    "loadingRequester",
    "loadingSpinner",
    "placeholder",
  ];

  public initialize() {
    this.addLoadingSpinnerListeners();
    this.addPlaceholderObservers();
  }

  private replaceContentWithLoadingSpinner(): void {
    this.hidePlaceholders();
    this.hideContent();
    this.showLoadingSpinners();
  }

  private replaceLoadingSpinnerWithContent(): void {
    this.updatePlaceholder();
    this.hideLoadingSpinners();
  }

  private addLoadingSpinnerListeners(): void {
    this.loadingRequesterTargets.forEach((requester) => {
      requester.addEventListener(ajaxCallbacks.send, () => {
        this.replaceContentWithLoadingSpinner();
      });

      requester.addEventListener(ajaxCallbacks.complete, () => {
        this.replaceLoadingSpinnerWithContent();
      });
    });
  }

  private showLoadingSpinners(): void {
    this.loadingSpinnerTargets.forEach((loadingSpinner) => {
      loadingSpinner.classList.remove(...cssClasses.hidden);
    });

    this.spinnerShowTime = new Date().getTime() + this.minimumSpinnerDuration;
  }

  private hideLoadingSpinners(): void {
    setTimeout(() => {
      this.loadingSpinnerTargets.forEach((loadingSpinner) => {
        loadingSpinner.classList.add(...cssClasses.hidden);
      });
    }, this.spinnerWaitDuration);
  }

  private showPlaceholders(): void {
    setTimeout(() => {
      this.placeholderTargets.forEach((placeholder) => {
        placeholder.classList.remove(...cssClasses.hidden);
      });
    }, this.spinnerWaitDuration);
  }

  private hidePlaceholders(): void {
    setTimeout(() => {
      this.placeholderTargets.forEach((placeholder) => {
        placeholder.classList.add(...cssClasses.hidden);
      });
    }, this.spinnerWaitDuration);
  }

  private showContent(): void {
    setTimeout(() => {
      this.contentTargets.forEach((target) => {
        target.classList.remove(...cssClasses.hidden);
      });
    }, this.spinnerWaitDuration);
  }

  private hideContent(): void {
    setTimeout(() => {
      this.contentTargets.forEach((target) => {
        target.classList.add(...cssClasses.hidden);
      });
    }, this.spinnerWaitDuration);
  }

  private hasNoChildren(element: Element): boolean {
    return element.children.length === 0;
  }

  private updatePlaceholder() {
    if (this.contentTargets.every(this.hasNoChildren)) {
      this.showPlaceholders();
      this.hideContent();
    } else {
      this.hidePlaceholders();
      this.showContent();
    }
  }

  private addPlaceholderObservers() {
    this.updatePlaceholder();

    const contentObserver = new MutationObserver(() => {
      this.updatePlaceholder();
    });

    this.contentTargets.forEach((target) => {
      contentObserver.observe(target, { childList: true });
    });
  }

  private get minimumSpinnerDuration(): number {
    const defaultSpinnerDuration = 500;

    return parseInt(
      this.data.get("spinnerDuration") || `${defaultSpinnerDuration}`,
      10
    );
  }

  private get spinnerWaitDuration(): number {
    return this.spinnerShowTime - new Date().getTime();
  }

  private get spinnerShowTime(): number {
    return parseInt(
      this.data.get("spinnerShowTime") || new Date().getTime().toString(),
      10
    );
  }

  private set spinnerShowTime(value: number) {
    this.data.set("spinnerShowTime", value.toString());
  }
}
