import { Controller } from "stimulus";

import Rails from "@rails/ujs";

// Whenever dates of a time off adjustment change, spin up requests to find conflicts.
export default class extends Controller {
  public static targets = [];

  private selectedDates: string[] = [];

  public dateChanged(): void {
    this.gatherDateValues();

    const updatables = [
      {
        elementID: "on-call-container",
        requestUrlDataAttribute: "onCallUrl",
      },
      {
        elementID: "bug-duty-container",
        requestUrlDataAttribute: "bugDutyUrl",
      },
      {
        elementID: "adjustment-container",
        requestUrlDataAttribute: "adjustmentUrl",
      },
    ];

    this.updateAssociatedData(updatables);
  }

  public initialize(): void {
    this.dateChanged();
  }

  // Find all date elements like the one this controller is on which have values set.
  private findDateInputsWithValues(): HTMLInputElement[] {
    const form = this.element.querySelector<HTMLFormElement>("form");

    if (form === null) {
      return [];
    }

    const dateInputs = Array.from(
      form.querySelectorAll<HTMLInputElement>('input[type="date"]')
    );

    const populatedDateInputs = dateInputs.filter(
      (element) => element.value !== ""
    );

    // Find only date selections with values set
    return populatedDateInputs;
  }

  private arrayToParams(array: string[], parameterName: string) {
    return array.map((element) => `${parameterName}[]=${element}`).join("&");
  }

  private gatherDateValues(): void {
    const dateElements = this.findDateInputsWithValues();
    const selectedDates = dateElements.map((element) => element.value);

    this.selectedDates = selectedDates;
  }

  private updateAssociatedData(updatables: any[]): void {
    updatables.forEach((updatable) => {
      const containerElement = document.getElementById(updatable.elementID);
      if (containerElement === null) {
        return;
      }

      Rails.ajax({
        type: "GET",
        url: this.data.get(updatable.requestUrlDataAttribute),
        dataType: "json",
        data: this.arrayToParams(this.selectedDates, "dates"),
        beforeSend: (xhr: any, options: any): boolean => {
          // Let the element respond to the data refresh
          Rails.fire(containerElement, "ajax:send", [xhr, options]);

          return true;
        },
        success: (response: any): void => {
          containerElement.innerHTML = response.html;

          // Let the element respond to the data refresh
          Rails.fire(containerElement, "ajax:complete", response);
        },
      });
    });
  }
}
