class AjaxCallbacks {
  get send(): string {
    return "ajax:send";
  }

  get error(): string {
    return "ajax:error";
  }

  get success(): string {
    return "ajax:success";
  }

  get complete(): string {
    return "ajax:complete";
  }
}

const ajaxCallbacks = new AjaxCallbacks();
export default ajaxCallbacks;
