import { Controller } from "stimulus";

class TimeOffDatePopulationBaseController extends Controller {
  public readonly element!: HTMLInputElement;
}

// When a new time off request date is added, intelligently populate the date field
// based on previous requests.
export default class extends (Controller as typeof TimeOffDatePopulationBaseController) {
  public static targets = [];

  public initialize(): void {
    if (this.elementDoesNotHaveValue()) {
      this.setDate();
    }
  }

  // In order to correctly apply a value to a date field, every number must be appropriately padded.
  private datePad(numberToPad: number): string {
    return ("0" + numberToPad).slice(-2);
  }

  // Given some date, find the next day and format it for a date input
  private nextDay(date: string): string {
    const previousDate = new Date(date);
    const newDate = new Date(previousDate);
    newDate.setDate(newDate.getDate() + 2);

    const dd = this.datePad(newDate.getDate());
    const mm = this.datePad(newDate.getMonth() + 1);
    const y = newDate.getFullYear();

    return `${y}-${mm}-${dd}`;
  }

  // Find all date elements like the one this controller is on which have values set.
  private findSiblingDateElementsWithValues(): HTMLInputElement[] {
    const form = this.element.closest("form");

    if (form === null) {
      return [];
    }

    const dateInputs = Array.from(
      form.querySelectorAll<HTMLInputElement>('input[type="date"]')
    );

    const populatedDateInputs = dateInputs.filter(
      (element) => element.value !== ""
    );

    // Find only date selections with values set
    return populatedDateInputs;
  }

  private dispatchNotificationEvent(): void {
    const event = document.createEvent("Event");
    event.initEvent(this.notificationEventName, true, true);
    this.element.dispatchEvent(event);
  }

  // Set the date of the element that this controller is attached to.
  private setDate(): void {
    const siblings = this.findSiblingDateElementsWithValues();

    // Return if there are no elements to pull dates from
    if (siblings.length < 1) {
      return;
    }

    const selectedDates = siblings.map(
      (element: HTMLInputElement) => element.value
    );
    const maxSelectedDate = selectedDates.sort().reverse()[0];
    this.element.value = this.nextDay(maxSelectedDate);

    this.dispatchNotificationEvent();
  }

  private elementDoesNotHaveValue(): boolean {
    return this.element.value === "";
  }

  private get notificationEventName(): string {
    return "initialDateSet";
  }
}
