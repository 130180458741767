import { Controller } from "stimulus";

import cssClasses from "lib/css_classes";

// This controller adds all necessary JS functionality to a Bulma navbar.
//
// @see https://bulma.io/documentation/components/navbar/#navbarJsExample
export default class extends Controller {
  public static targets = [];

  public toggleMobileMenu(event: Event): void {
    this.activatables.forEach((selector) => {
      cssClasses.active.forEach((cssClass) => {
        const activatableElement = this.element.querySelector(selector);

        if (activatableElement) {
          activatableElement.classList.toggle(cssClass);
        }
      });
    });
  }

  private get activatables(): string[] {
    return [".navbar-burger", ".navbar-menu"];
  }
}
