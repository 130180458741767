import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("input", () => {
      this.formatInput(this.element as HTMLInputElement);
    });

    this.formatInput(this.element as HTMLInputElement);
  }

  formatInput(input: HTMLInputElement): void {
    let value = input.value.split(".")[0];

    value = value.replace(/,/g, "");

    const regex = /(\d+)(\d{3})/;
    while (regex.test(value)) {
      value = value.replace(regex, "$1" + "," + "$2");
    }

    input.value = value;
  }
}
