import { Controller } from "stimulus";

// Add auto-dismissing behavior to flash notifications.
export default class extends Controller {
  public static targets = [];

  public initialize(): void {
    this.updateProgressBar();
  }

  // Remove the given element from the DOM
  private removeElement(): void {
    const parentElement = this.element.parentNode;

    if (parentElement !== null) {
      parentElement.removeChild(this.element);
    }
  }

  // Slowly decrement the progress bar on the element, removing the element when complete.
  private updateProgressBar(): void {
    const progressBar =
      this.element.querySelector<HTMLProgressElement>("progress.progress");

    if (progressBar === null) {
      return;
    }

    const finalPercentage = 0;
    let currentPercentage = progressBar.value;

    const update = setInterval(() => {
      if (currentPercentage <= finalPercentage) {
        clearInterval(update);
        this.removeElement();
      }

      currentPercentage -= this.decrementProgressStep;
      progressBar.value = currentPercentage;
    }, this.decrementMsInterval);
  }

  private get decrementMsInterval(): number {
    return 1;
  }

  private get decrementProgressStep(): number {
    return 0.07;
  }
}
